@import './variables';

:global {
    .customTooltip {
        color: $white !important;
        background: $primary !important;

        &.place-top:after {
            border-top-color: $primary !important;
        }

        &.place-left:after {
            border-left-color: $primary !important;
        }

        &.place-right:after {
            border-right-color: $primary !important;
            transform: translateY(-7px);
        }

        &.place-bottom:after {
            border-bottom-color: $primary !important;
        }
    }
}