
@import '../../style/variables';

.rel {
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
}

.actionToggle {
    position: absolute;
    width: 2.5rem;
    min-height: 2.5rem;
    display: grid;
    grid-template-rows: 2.5rem;
    justify-content: center;
    align-items: center;
    background: $white;
    border-radius: 2rem;
    cursor: pointer;

    transition: box-shadow 600ms ease-in-out;

    &:hover,
    &.active {
        background: $gray-light;
        color: $primary;
        grid-template-rows: 2.5rem 1fr;
        z-index: 100;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

        span:first-child {
            color: $primary;
        }
    }
}

.actionToggleOpened {
    position: relative;
    display: grid;
    grid-template-rows: 2.5rem 1fr;
    justify-content: center;
    align-items: center;
    & > * {
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.overflow {
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
}