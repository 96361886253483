@import "../../style/variables";

#welcome {
    .main {
        .box {
            width: 100%;
            text-align: left;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            h3 {
                margin-top: .75rem;
            }

            &_wrapper {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-gap: 3rem;
                width: 100%;
                margin-bottom: 4rem;
            }

            &_content {
                margin-bottom: 1rem;

                p:last-child {
                    margin-bottom: 0;
                }
            }

            img {
                margin: auto;
                width: auto;
            }

            a {
                max-width: fit-content;
                align-self: center;
            }
        }
    }

    //need z-index for safari
    .image_container {
        z-index: 0;
    }
}


.link_arrow {
    display: flex;
    align-items: center;
    line-height: 1em;
    font-size: .7rem;
    font-weight: 900 !important;
    cursor: pointer;
    color: $primary;
    text-decoration: none;

    &:hover:after {
        transform: translateX(3px);
    }

    &:after {
        content: '';
        background-image: url("../../assets/icons/arrow-scribble-big.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: .9rem;
        height: 1em;
        left: 0;
        display: inline-block;
        margin-left: 6px;
        position: relative;
        transition: transform ease-in-out .6s;
    }
}


.listChartEntry {

    p {
        font-size: .8rem !important;
    }
}

.listChartEntryBold {
    @extend .listChartEntry;
    font-weight: bold;
}

.analyticsBox {
    background: $gray-white;
    border-radius: .25rem;
    padding: 1rem 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    position: relative;

    a {
        position: absolute;
        bottom: 0;
        transform: translateY(50%);
        margin: 0;
        max-width: calc(100% - 2rem) !important;
    }
}

.analyticsBox_row {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: flex-end;
    margin-bottom: 1rem;
    position: relative;

    span:first-child {
        font-weight: $bold;
        font-size: 75%;
        position: relative;
        top: -1px;
    }

    span:last-child {
        position: relative;
        top: 2px;
    }
}

.analyticsBox_totals {
    position: absolute !important;
    font-size: .5rem !important;
    width: 3rem;
    top: -0.5rem !important;
    right: 0;
    text-align: right;
    opacity: .5;
}

.analyticsBox_border {
    flex: 1;
    width: 100%;
    height: 1px;
    border-bottom: 2px dotted currentColor;
    position: relative;
    top: -4px;
    margin: 0 0.25rem;
}

.flexRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.calendarRow {
    display: flex;
    justify-content: center;
    align-items: center;
    color: transparentize($black, 0.5);
    font-size: .65rem;
    grid-gap: .25rem;
    font-family: $robotoSlab;
}