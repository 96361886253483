.linkTable {
  grid-column: span 2;

  .listChartEntry {
    
    p {
      font-size: .8rem !important;
    }
  }

  .listChartEntryBold {
    @extend .listChartEntry;
    font-weight: bold;
  }


}