@import '../../style/variables';

.fullWidth {
    display: grid;
    grid-template-rows: minmax(calc(100vh - (var(--headerWidth) * 3)), 1fr) auto;

    table {
        border-spacing: 0;
        width: 100%;
        border: none;
        font-size: 14px;

        tr {
            &:last-child {
                td {
                    border-bottom: 0;
                }
            }
        }

        th,
        td {
            margin: 0;
            padding: 0.5rem;
            font-size: 14px;

            :last-child {
                border-right: 0;
            }
        }

        thead th {
            border-bottom: 1px solid $gray-medium;
            padding: 1rem 0.5rem;
        }
    }
}

.mono {
    font-family: $robotoMono;
    font-weight: 700;
    font-size: 12px;
}

.title {
    font-weight: 600;
}

.link {
    font-weight: 600;
    color: $primary;
}

.clicks {
    font-family: $robotoMono;
    font-weight: 700;
    opacity: 0.7;
}

.groups {
    opacity: 0.7;
}

.searchContainer {
    display: grid;
    grid-template-columns: minmax(150px, 300px) minmax(150px, 300px) auto;
    grid-gap: 1rem;
    margin-bottom: 1rem;
}

.inputWithIcon {
    background: $gray-light;
    border-radius: 2rem;
    display: flex;
    align-items: center;

    input {
        background-color: transparent;
        border: none;
        color: $black;
        font-family: $robotoSlab;
        font-weight: 700;
        padding-left: 0;
    }
}

.inputIcon {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.filterContainer {
    color: $gray-dark;
    font-family: $robotoSlab;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;
    grid-gap: .5rem;
}

.filterIcon {
    border-radius: 2rem;
    color: $black;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover,
    &.active {
        background: $gray-light;
        color: $primary;
    }

    cursor: pointer;
}

.sortable {
    cursor: pointer;
    color: $primary;
}


.copyCell {
    max-width: 300px;
}

.overflow {
    max-width: 200px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.overflowUser {
    max-width: 150px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tooltip {
    transform: translateY(-10px);
}

.cellFlex {
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
}

p {
    color: $black;
    line-height: 2em;
    margin: 0;

    &.small {
        color: $black;
        font-size: .6rem;
        font-weight: $regular;
        line-height: 1.5;
    }

    &.big {
        font-size: 24px;
        line-height: 1em;
        font-weight: $bold;
    }

    &.label {
        font-size: .5rem;
        padding: 2px .25rem;
        margin: 0 .5rem;
        border: 1px solid $primary;
        border-radius: $borderRadius;
        height: 1rem;
        color: $primary;
        font-weight: 600;
        display: inline-block;
        line-height: 1.6em;
    }

    &.copyHeight {
        line-height: 2rem;
    }
}