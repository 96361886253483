@import "src/style/variables";
@import "src/style/mixins";

#footer {
    grid-row: 2;
    grid-column: 2;
    @include width-wrapper;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;

    .logo {
        width: 2rem;
        opacity: .4;
    }
}