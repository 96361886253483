@import '../../style/variables';
@import '../../style/mixins';

.active {
    color: $primary !important;
}

.header {
    --headerWidth: 6.5rem;
    grid-column: 1;
    grid-row: span 2;
    font-family: $roboto;
    position: sticky;
    top: 0;
    left: 0;
    width: var(--headerWidth);
    height: 100vh;
    padding: 0;
    background: $white;
    z-index: 999;
    line-height: var(--headerWidth);

    &::after {
        content: "";
        display: block;
        height: 100%;
        width: 2px;
        background-color: $primary;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
    }

    div.mainNavigation {
        display: grid;
        grid-template-rows: var(--headerWidth) 1fr calc(var(--headerWidth) * 2);
        height: 100%;
        width: 100%;
        position: relative;
        z-index: 1;
        background-color: $white;

        .logo {
            grid-row: 1;
            display: flex;
            flex-direction: column;
            width: var(--headerWidth);
            height: var(--headerWidth);
            align-items: center;
            justify-content: center;

            img {
                width: calc(var(--headerWidth) * 0.5);
            }
        }

        nav {
            grid-row: 2;

            a,
            div {
                display: flex;
                flex-direction: column;
                width: var(--headerWidth);
                height: calc(var(--headerWidth) / 2);
                align-items: center;
                justify-content: center;
                color: currentColor;

                &:hover {
                    color: $primary;
                }

                span {
                    line-height: 1;

                }

                svg {
                    width: 1.5rem;
                    height: 1.5rem;
                }
            }

            img {
                display: inline-block;
            }

            &.nav_icon {
                grid-row: 3;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;

                a,
                .nav_container {
                    img {
                        width: .9rem;
                    }

                    img.active {
                        display: none;
                    }
                }
            }
        }
    }

    .createNavigation {
        display: none;
        position: absolute;
        background-color: $white;
        width: 100%;
        height: 100vh;
        top: 0;
        grid-row: span 2;
    }

    nav.adminNavigation {
        grid-row: 3 !important;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .nav_icon {
            cursor: pointer;

            span {
                width: 2.5rem;
                height: 2.5rem;
                display: flex;
                align-self: center;
                justify-self: center;
                justify-content: center;
                align-items: center;
                border-radius: 100px;
            }

            &:hover span {
                background: $primary;
                color: $white !important;

            }
        }

        span.active {
            background: $primary;
            color: $white !important;

            &:hover {
                background: $primary-hover;
                color: $white !important;
            }

            div:hover {
                color: $white !important;
            }
        }
    }

    nav.settingsNavigation,
    nav.createNavigation {
        display: flex;
        flex-direction: column;
        position: absolute;
        background-color: $white;
        max-width: 0;
        width: 450px;
        padding: 0 2rem 1rem calc(var(--headerWidth) + 2rem);
        height: 100vh;
        top: 0;
        grid-row: span 2;
        z-index: 0;
        overflow: hidden;
        transition: 600ms max-width ease-in-out;
        transform: translateX(-100%);

        &::after {
            content: "";
            display: block;
            height: 100%;
            width: 2px;
            background-color: $primary;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1;
        }

        .nav_header {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 1.5rem;
            width: 100%;
            height: var(--headerWidth);
            font-family: $robotoSlab;
            font-weight: $bold;
            opacity: 0;
            cursor: pointer;
            transition: 300ms opacity ease-in-out 300ms;

            svg {
                width: 1.5rem;
                height: 1.5rem;
                margin-right: 1rem;
            }
        }

        ul,
        li {
            list-style: none;
            margin: 0;
            padding: 0;
            width: 100%;
        }

        li {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: calc(var(--headerWidth) / 2);
            align-items: center;
            justify-content: flex-start;
            color: currentColor;
            transition: 300ms opacity ease-in-out 300ms;
            opacity: 0;
        }

        a {
            color: $primary;
            font-family: $robotoSlab;
            font-weight: $bold;
            cursor: pointer;
            text-decoration: none;
            transition: all 600ms ease-in-out;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            flex: 1;
            line-height: calc(var(--headerWidth) / 2);

            span {
                line-height: calc(var(--headerWidth) / 2);
            }

            svg {
                width: 1.5rem;
                height: 1.5rem;
                top: .33rem;
                color: $black;
                position: relative;
                margin-right: .75rem;
            }

            &:hover {
                color: $primary;
            }
        }

        &Active {
            max-width: 9999px;
            transform: translateX(0);

            li,
            .nav_header {
                opacity: 1;
            }
        }
    }

    nav.createNavigation {
        a {
            color: $black;
            font-family: $robotoSlab;
            font-weight: $bold;
            font-size: 1.25rem;

            &:hover {
                color: $primary-hover;
            }
        }
    }

    .btn_icon_rounded_pulse {
        background: $primary;
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5rem;
        color: $white;
        position: relative;
        cursor: pointer;

        &:after {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 5rem;
            background: $primary;
            opacity: .25;
            z-index: 0;
            animation-name: zoomInUp;
            animation-duration: 2s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
            animation-direction: alternate;
        }

        svg {
            z-index: 1;
        }
    }
}

.createNavElementActive {
    position: relative;
}
