// Not the best solution, but working

@keyframes :global(zoomInUp) {
    from {
        transform: scale3d(1.5, 1.5, 1);
    }

    to {
        transform: scale3d(0.75, 0.75, 1);
    }
}
