@import '../../style/variables';
@import '../../style/mixins';

$trend-spacer: 3rem;
$card-sizer: 350px;

.grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(200px, 33%));
    grid-column-gap: 150px;
    grid-row-gap: 2.5rem;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 60px;
}

.linkGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax($card-sizer, 1fr));
    grid-template-rows: auto;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    width: 120%;
    padding-top: 2rem;
    margin-left: -10%;
    margin-right: -10%;
}

.linkGridItem {
    background: $gray-white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    border-bottom-right-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;
}

.highlight {
    box-shadow: 0 0 5px transparentize($primary, 0.25);
}

.cardImgWrapper {
    width: 100%;
    padding-top: 52.5%;
    position: relative;
    overflow: hidden;
    color: $gray-dark;

    img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
    }
}

.nothingFoundContainer {
    grid-row: span 2;
    grid-column: span 3;
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nothingFound {
    background-color: transparentize($lh-warning, .9);
    color: $lh-warning;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    flex: 1;
    font-size: 80%;
    grid-gap: 0.5rem;
    text-transform: uppercase;
}

.available{
    background-color: transparentize($primary, .9);
    color: $primary;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    flex: 1;
    font-size: 80%;
    grid-gap: 0.5rem;
    text-transform: uppercase;
}

.cardContent {
    padding: 1rem .5rem .5rem;
    width: 100%;
    flex: 1;
    font-size: 90%;
    position: relative;
}

.cardRow {
    display: grid;
    grid-template-columns: 1rem 1fr;
    grid-gap: .5rem;
    align-items: center;
    line-height: 14px;
    padding-left: 3.5rem;

    &:first-child {
        margin-bottom: 0.5rem;
    }

    &.three {
        grid-template-columns: $trend-spacer 1fr $trend-spacer;
    }

    &.two {
        grid-template-columns: 1fr $trend-spacer;
    }
}

.mono {
    font-family: $robotoMono;
    font-weight: 700;
    font-size: 12px;
}

.title {
    font-weight: 600;
    line-height: 1.5;
}

.link {
    font-weight: 600;
    color: $primary;
}

.groups {
    opacity: 0.7;
    font-size: 13px;
}

.clicks {
    font-family: $robotoMono;
    font-weight: 700;
    opacity: 0.7;
    font-size: 13px;
    text-align: center;
}

.noteBadge {
    position: absolute;
    background: $white;
    border-radius: 100%;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    top: 1.75rem;
    left: .5rem;
}

.actionTogglerGrid {
    position: absolute;
    right: .5rem;
    top: -1.25rem;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 100%;
}

.favoriteTogglerGrid {
    position: absolute;
    background: $white;
    left: .5rem;
    top: -1.25rem;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 100%;
}

.notes {
    color: $black;
    transition: 500ms color ease-in-out;
    cursor: pointer;

    &:hover {
        color: $primary-hover;
    }
}


.notesTooltip {
    max-width: 300px;

    span {
        font-family: $robotoSlab;
        font-weight: 600;
        font-size: 0.75rem;
        margin-bottom: .25rem;
        display: inline-block;
    }

    p {
        margin: 0;
        white-space: pre-line;
        color: $white !important;
        line-height: 1.5rem !important;
    }
}

.overflow {
    max-width: calc($card-sizer - ($trend-spacer * 2) + 1rem);
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;

    a {
        color: $primary;
        font-family: $robotoSlab;
    }
}

.copyField {
    margin-top: 1rem;

    &>div>div {
        padding: 0.5rem 0.5rem 0.5rem 1rem !important;
        background-color: #ffffff !important;

        &>a {
            max-width: calc($card-sizer - 2.5rem) !important;
        }
    }
}