@import "../../../style/variables";

.template-grid {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 60px;
}

h3 {
    font-size: 22px;
    width: 100%;
    color: $black !important;
}

.field_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.formInputs {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 1rem;

    // >div {
    //     width: 100%;
    //     display: flex;
    //     justify-content: space-between;
    // }
}

.button_wrapper {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1rem;

    &>*:first-child {
        margin-right: 2.5rem;
    }
}

.content-container {
    >* {
        margin-top: 0;
    }

    h1 {
        margin-bottom: 0.5rem;
    }

    table {
        margin: 1.5rem 0 1rem 0;
        display: table;
        flex-direction: none;

        * {
            vertical-align: top;
            line-height: 1.4em;
        }

        .shorten {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 400px;
        }
    }
}

.tableGeneral,
.tableShortlinks {
    background: $gray-light;
    padding: 1rem;
    border-radius: 5px;
    font-size: 0.825rem;
    margin: 0 0 2rem;

    * {
        text-align: left;
    }

    td {
        min-width: 120px;
        font-weight: $regular;
    }

    tr td:first-child {
        font-weight: 700;
    }

    tr {
        padding-bottom: 1rem;
        display: block;

        &:last-child {
            padding-bottom: 0;
        }
    }

    thead {
        height: 2rem;
    }
}

.tableShortlinks {
    .shortlinks {
        min-width: 250px;
    }

    .tags {
        min-width: 250px;
        white-space: nowrap;
        position: relative;
        padding-left: 1rem;

        &:after {
            content: '';
            width: .5rem;
            height: 100%;
            background: linear-gradient(90deg, rgba(255, 255, 255, 0), $gray-light);
            display: inline-block;
            position: absolute;
            right: 0;
            top: 0;
        }

        .scroll-container {
            width: 100%;
            height: 100%;
            overflow-x: auto;
            overflow-y: hidden;
            padding-right: 2.5rem;
            max-width: 300px;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
}