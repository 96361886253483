@import "mixins";
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;700&family=Roboto+Mono:wght@400;600&display=swap');


html {
    // change it to change aspect-ratio of rem ... currently 1rem = 1rem
    font-size: 125%;

    @media screen and (max-width: 1025px) {
        font-size: 100%;
    }

    @media screen and (max-width: 769px) {
        font-size: 80%;
    }
}

body {
    font-size: 16px;
}

:global {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.4em;
        margin: 1.5rem 0 1rem 0;

        &.primary {
            color: $primary
        }

        &.center {
            text-align: center;
            width: 100%;
            margin: auto;
        }

        +h1,
        +h2,
        +h3,
        +h4,
        +h5,
        +h6,
        +p {
            margin-top: 0;
        }

    }

    h1,
    h2,
    h3,
    h4 {
        font-family: $robotoSlab;
        font-weight: $bold;
        color: $black;
    }

    h1 {
        font-size: 2rem;
        width: 100%;

        // +div {
        //     margin-top: 2rem !important;
        //     padding-top: 0 !important;
        // }
    }

    h2 {
        font-size: 1.5rem;
    }

    h3 {
        font-size: 1rem;
    }

    h4,
    h5 {
        font-size: .9rem;
        font-weight: $medium;
    }

    h6 {
        font-size: .9rem;
        line-height: 1.6rem;
        font-weight: $bold;
        margin: 0;
    }

    p {
        line-height: 1.8em;
    }

    a {
        color: $primary;
        font-weight: $bold;
        cursor: pointer;
        text-decoration: none;
        transition: all 600ms ease-in-out;
    }

    .btn-rounded-icon {
        border-radius: 100%;

        span {
            position: relative;
            z-index: 1;
        }

        &::before {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            transition: all 600ms ease-in-out;
            transform-origin: center center;
            background: inherit;
            border-radius: 100%;
            opacity: .5;
            z-index: 0;
        }

        &:hover {
            &::before {
                transform: scale(1.25);
            }
        }
    }

    *::selection {
        background: $primary !important;
        color: $white !important;
        text-shadow: none !important;
    }

    button {
        -webkit-appearance: none;
        cursor: pointer;

        &:focus,
        &:active {
            outline: none;
        }
    }

    .robotoSlab {
        font-family: $robotoSlab !important;
    }

    .bold{
        font-weight: 600;
    }

    .f {
        &__xl {
            font-size: 175%;
        }

        &__l {
            font-size: 150%;
        }
    }
}