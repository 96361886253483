@import "../../style/variables";

.logo_header {
    position: fixed;
    width: 46px;
    height: auto;
    margin: .5rem;
    top: 0;
}

#signup {
    max-width: 450px;
    z-index: 100;
    background: $white;
    padding: 2rem;
    border-radius: 7px;
    width: 100%;
    left: 60px;
    top: 50%;
    position: relative;
    transform: translateY(-50%);
    box-shadow: 0 0 .75rem rgba(0, 0, 0, 0.2);

    .header {
        margin-bottom: 1.5rem;

        h2 {
            font-size: 1.5rem;
            margin: 0;
        }

        p {
            cursor: pointer;
            color: $gray-dark;
        }
    }

    .logo_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .logo {
            width: 192px;
            margin-bottom: 1rem;
        }

        h1 {
            margin: .5rem 0 1.5rem 0;
            font-size: 43px;
        }
    }

    .success {
        margin: 1rem 0;
        font-size: .8rem;
    }

    .terms, .terms * {
        margin: 1rem 0;
        font-size: .6rem;
        color: $gray-dark;

        a {
            color: $black;
            text-decoration: underline;
        }
    }

    a {
        margin: 0 auto;
        font-size: .6rem;
        text-decoration: none;
        color: $black;
        font-weight: $medium;
    }

    button {
        max-width: 100%;
        width: 100%;
        margin: 1rem 0 0 0;
    }

    form {
        >div {
            margin-bottom: 0;

            >div {
                margin-bottom: .5rem;
            }
        }
    }
}
