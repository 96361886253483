@import '../../style/variables';

.copyToClipboard {
    display: inline-flex;
    width: 100%;

    .iconWrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: relative;
        border-radius: 1.5rem;
        padding: 0.25rem 0rem 0.25rem 1rem;
        background-color: $gray-light;
        width: 100%;
        transition: background-color 500ms ease-in-out;

        .linkOverflow {
            line-height: 2em !important;
            max-width: calc(100% - 1rem);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-right: 1rem;
            font-weight: 700;
            font-family: $robotoSlab;
        }

        span.icon {
            padding-right: 0.5em;
            cursor: pointer;
            filter: brightness(0);
            transition: filter ease-in-out 0.6s;
            line-height: 100%;

            svg {
                width: 1rem;
                height: 1rem;
            }

            &:hover {
                filter: brightness(1);
                transition: filter ease-in-out 0.6s;
            }
        }

        span.iconBlocked {
            padding-right: 0.5em;
            cursor: not-allowed;
            filter: brightness(0);
            transition: filter ease-in-out 0.6s;
            line-height: 100%;

            svg {
                width: 1rem;
                height: 1rem;
            }
        }

        .tooltip {
            opacity: 0;
            transition: opacity ease-in-out 0.6s;
            position: absolute;
            white-space: nowrap;
            background: $primary;
            color: $white;
            border-radius: 3px;
            font-size: 14px;
            line-height: 1em;
            padding: 12px 15px;
            z-index: 100;
            top: -2.5rem;
            right: calc(15px);
            transform: translateX(50%);
            pointer-events: none;

            &[data-visible='true'] {
                opacity: 1;
            }

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                display: inline-block;
                background: $primary;
                color: $white;
                width: 0.5rem;
                height: 0.5rem;
                transform: translateX(-50%) translateY(50%) rotate(45deg);
            }
        }
    }
}