@import "../../../../style/variables";

.input_wrapper {
    margin-bottom: 1rem;
}

label + div {
    margin-top: .25rem;
}

.infoText {
    font-size: .6rem !important;
    line-height: 1.25em;
    text-align: center;
    margin: .5rem 0 0 0;
    font-weight: $regular;
    color: $gray-medium-light;
}

.error_message {
    margin-bottom: 0px;
    min-height: 1.25rem;
    line-height: 1.25rem;
    padding-top: 2px;
    margin-top: 2px;
    text-align: center;
    font-size: .6rem;
    font-family: $roboto;
    font-weight: normal;
    color: $primary;
    background-image: url('/assets/icons/warning.svg') !important;
    background-position: right 0px center;
    background-repeat: no-repeat;
    background-size: .75rem;
    position: relative;
}