@mixin width-wrapper {
    max-width: 1300px;
    width: 80%;
    margin: 0 auto;

    @media (max-width: 1300px) {
        max-width: 80%;
    }
}

@mixin flex-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@mixin hyphens {
    -moz-hyphens: auto;
    -o-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}