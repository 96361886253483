
.linkButton {
    &:before {
        content: '';
        background-image: url("../../assets/icons/chevron-left.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 1rem;
        height: .6rem;
        left: 0;
        top: -0.5px;
        display: inline-block;
        margin-right: 6px;
        position: relative;
    }
}
