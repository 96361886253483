#createUser {
    h1 {
        margin: 0;
    }

    .button_wrapper {
        display: flex;
        justify-content: flex-start;
        margin: 1rem 0;

        &>*:first-child {
            margin-right: 2.5rem;
        }
    }

    .formInputs {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 2.5rem auto .5rem auto;
    }
}