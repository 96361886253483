$borderRadius: 3;
$paddingHorizontal: 8px;

@import "../../style/variables";

.filterbar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .dateFilter {
        position: relative;

        +.copyButton {
            margin-left: 1rem;
        }

        .divider {
            display: inline-block;
            background: $primary;
            margin: 0 .5rem;
            width: .6rem;
            height: 1px;
            vertical-align: middle;
        }
    }
}

.filter {
    display: inline-block;
    padding: 0.75rem 0.5rem 0.75rem 1rem;
    border: 1px solid $primary;
    border-radius: $borderRadius;
    height: 2.5rem;
    line-height: 1rem;
    cursor: pointer;

    .dropdownIcon {
        margin-left: .75rem;
        vertical-align: middle;
        width: .5rem;
        height: .5rem;
        top: -2px;
        display: inline-block;
        position: relative;
    }

    .copyIcon {
        width: .5rem;
        margin-right: .5rem;
        vertical-align: middle;
    }
}

.dateFilter {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
}

.copyButton {
    @extend .filter;
    padding: .5rem 1rem;
}

.calendarWrapper {
    position: absolute;
    height: auto;
    width: 390px;
    top: 0;
    right: 0;
    z-index: 10;
    background: $white;
    border-radius: .25rem;
    box-shadow: 0px .5rem 1rem #00000029;
}

.calendarCloseBtn {
    background-image: url("../../assets/icons/close.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    transform: translateX(50%) translateY(-50%);
    width: 28px;
    height: 28px;
    top: 0px;
    right: 0px;
    z-index: 100;
    display: inline-block;
    position: absolute;
    border: none;
    background-color: $white;
}

.calendarNavBar {
    display: flex;
    // background-color: yellow;
    padding: 0px $paddingHorizontal 0px $paddingHorizontal;
    font-weight: bold;

    button,
    input[type="button"] {
        height: 26px;
        width: 2rem;
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        // background-color: red;

        img {
            margin: 0px 6px 0px 6px;
            width: 6px;
            display: inline;
        }
    }

    button:hover {
        filter: invert(0.5);
    }

}

.calendarNavBar>div {
    flex: 80%;
    text-align: center;
    font-size: .7rem;
}

.calendarNavBar>div:first-child,
.calendarNavBar>div:last-child {
    // background-color: blue;
    flex: 10%;
}

.calendarRangePresets {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: .6rem $paddingHorizontal 2px $paddingHorizontal;
    // background-color: cadetblue;

    button,
    input[type="button"] {

        border-radius: 2px;
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        height: 1.5rem;
        font-size: 13px;
        padding: 0px .8rem 0px .8rem;
        background-color: #EFEEEA;
        margin: 0 -12px;

    }

    button:hover {
        background-color: #318692;
        font-weight: bold;
        color: $white;
    }
}

.calendarRangePresetsButtonActive {
    background-color: #318692 !important;
    font-weight: bold !important;
    color: $white !important;
}
