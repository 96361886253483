@import '../../style/variables';

.mono {
    font-family: $robotoMono;
    font-weight: 700;
    font-size: 12px;
}

.title {
    font-weight: 600;
}

.link {
    font-weight: 600;
    color: $primary;
}

.clicks {
    font-family: $robotoMono;
    font-weight: 700;
    opacity: 0.7;
}

.groups {
    opacity: 0.7;
}

.searchContainer {
    display: grid;
    grid-template-columns: minmax(150px, 300px) minmax(150px, 300px) auto;
    grid-gap: 1rem;
    margin-bottom: 1rem;
}

.inputWithIcon {
    background: $gray-light;
    border-radius: 2rem;
    display: flex;
    align-items: center;

    input {
        background-color: transparent;
        border: none;
        color: $black;
        font-family: $robotoSlab;
        font-weight: 700;
        padding-left: 0;
    }
}

.inputIcon {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.absoluteAdd {
    position: absolute !important;
    right: 0;
    top: 1.5rem;
}

.viewContainer,
.filterContainer {
    color: $gray-dark;
    font-family: $robotoSlab;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;
    grid-gap: .25rem;

    .mr {
        margin-right: .75rem;
    }

    &.active {
        color: $primary;
    }

}

.viewContainer {
    justify-content: flex-end;
}

.exportWithFilter {
    padding: .5rem;
    background-color: $gray-white;
    border-radius: $borderRadius;
}

.exportContainer {
    display: flex;
    justify-content: flex-end;
    grid-gap: 1rem;
    grid-column: 2;
    grid-row: 1;
    align-self: end;
    margin-bottom: 0.9rem;
}

.flexRow {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.filterIcon {
    border-radius: 2rem;
    color: $black;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover,
    &.active {
        background: $gray-light;
        color: $primary;
    }

    &.danger {
        background-color: transparentize($lh-warning, .9);
        color: $lh-warning;
    }

    &.alertAvailable {
        color: $lh-warning !important;
    }

    cursor: pointer;
}

.filterWithDescription {
    display: grid;
    align-items: center;
    grid-gap: 0.5rem;
    grid-template-columns: 2.5rem 1fr;
    width: 300px;
    font-size: 12px;
}

.alertCount {
    font-family: $roboto;
    font-weight: 400;

    span {
        color: $lh-warning !important;
    }
}

.sortable {
    cursor: pointer;
    color: $primary;
}


.copyCell {
    max-width: 300px;
}

.overflow {
    max-width: 200px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.overflowUser {
    max-width: 150px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.notes {
    color: $black;
    transition: 500ms color ease-in-out;
    cursor: pointer;

    &:hover {
        color: $primary;
    }
}

.tooltip {
    transform: translateY(-10px);
}

.notesTooltip {
    max-width: 300px;

    span {
        font-family: $robotoSlab;
        font-weight: 600;
        font-size: 0.75rem;
        margin-bottom: .25rem;
        display: inline-block;
    }

    p {
        margin: 0;
        white-space: pre-line;
        color: $white !important;
        line-height: 1.5rem !important;
    }
}

.border {
    height: 100%;
    width: 1px;
    border-radius: 3px;
    background-color: $gray-medium-light;
    margin: 0 .5rem;
}