@import '../../../../../style/variables';

.character_box {
    &_default,
    &_warning {
        display: inline-block;
        border-radius: .25rem;
        padding: .5em 1em .5em 2.5em;
        margin: -10px 0 1rem 0;
        font-size: .8em !important;
        position: relative;
        transition: all ease-in-out 300ms;

        &:before {
            content: '';
            display: inline-block;
            position: absolute;
            width: 1.2em;
            height: 1.2em;
            left: .8em;
            top: 50%;
            transform: translateY(-50%);
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 100%;
        }
    }

    &_default {
        background-color: $gray-white;

        &:before {
            background-color: rgba(49, 134, 146, 0.2);
            background-image: url("../../../../../assets/icons/tick.svg");
            background-size: .6em;
        }
    }

    &_warning {
        background-color: $orange-light;

        &:before {
            background-color: rgba(255, 110, 0, 0.2);
            background-image: url("../../../../../assets/icons/exclamation-mark.svg");
            background-size: .15em;
        }
    }
}