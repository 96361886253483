@import '../../style/variables';

.resend_email_confirm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    width: 100%;

    .counter {
        margin: 0;
        font-size: small;
        color: $gray-dark
    }

    * {
        margin-bottom: 0;
    }
}
