@import '../../style/variables';

.background {
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    position: absolute;
    opacity: 1;
    transition: linear opacity 230ms;
    background-size: 1440px;
    background-position: center left;
    background-repeat: no-repeat;
    background-image: url("../../assets/login.jpg");

    @media screen and (min-height: 1200px), screen and (min-width: 1440px) {
        background-size: cover;
    }

    @media (max-width: 768px) {
        background-size: cover;
        height: 100vh;
        background-position: center 0;
    }

    @media (max-width: 450px) {
        background-position: center top;
    }

    @media screen and (min-height: 800px) and (max-width: 400px) {
        background-position: center 2rem;
    }

    @media screen and (max-height: 600px) and (max-width: 330px) {
        background-position: center -10px;
    }

    .copyright {
        font-size: .6rem;
        color: $white;
        left: 60px;
        bottom: 1.25rem;
        margin: 0;
        position: absolute;
        max-width: 80%;

        a {
            color: $white;
            font-weight: 400;

            &:hover {
                text-decoration: underline;
            }

            + a {
                margin-left: .5em;

                &::before {
                    content: '|';
                    color: $white;
                    margin-right: .5em;
                }
            }
        }
    }
}
