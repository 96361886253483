@import '../../style/tags';
@import "../../style/variables";

.tag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 7px .5rem;
    border-radius: 3px;
    background: $tagBackground;
    color: #FFF;
    font-size: .7rem;
    cursor: default;
    height: 1.5rem;
    transition: background .6s ease;
    
    &.hidden {
        display: none;
    }
    
    &.clickable {
        cursor: pointer;
        
        &:hover {
            background: darken($tagBackground, 5%);
        }
    }
    
    + .tag {
        margin-left: .5rem;
    }
}
