@import '../../style/variables';

.snackbar_wrapper {
    position: fixed;
    right: 0;
    bottom: 2rem;
    transform: translateX(100%);
    transition: transform ease-in-out 300ms;
    z-index: 1000;

    .snackbar {
        position: relative;
        display: flex;
        vertical-align: center;
        justify-content: center;
        margin: 0;
        padding: 1rem;
        color: $white;
        border-radius: .5rem 0 0 .5rem;
        font-size: .7rem;
        font-family: $roboto;
        font-weight: $bold;
        z-index: 1000;
    }

    &.error {
        .snackbar {
            background: $lh-warning;
        }

    }

    &.success {
        .snackbar {
            background: $white;
            color: $black;
            border-left: 1px solid $primary;
            border-top: 1px solid $primary;
            border-bottom: 1px solid $primary;
        }
    }

    &.show {
        transform: translateX(0%);
        transition: transform ease-in-out 300ms;
    }
}