.formInputs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.formButtons {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
}
