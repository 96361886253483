$relativ-width: 75%;

#branding {


    :global(.selectbox__single-value) {
        font-size: .7rem;
    }

    h1 {
        margin-bottom: 0;

        + div {
            margin-top: 1rem !important;
        }
    }

    h3:first-child {
        margin-top: 0;
    }

}
.button_wrapper {
    width: $relativ-width;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.half {
    width: 100%;
}
