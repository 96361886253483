@import '../../../../style/variables';

.input_wrapper {
    display: flex;
    align-items: center;

    label {
        font-weight: $regular;
        margin-bottom: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            position: relative;
            width: 1em;
            height: 1em;
            margin-left: .25rem;

            img {
                filter: brightness(0);

                & + p {
                    font-size: .7rem !important;
                    line-height: 1.4;
                    padding: 8px;
                    position: absolute;
                    top: -10px;
                    margin: 0;
                    transform: translateY(-100%) translateX(-50%);
                    left: 50%;
                    white-space: nowrap;
                    color: $white;

                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        display: inline-block;
                        background: $primary;
                        color: $white;
                        width: .25rem;
                        height: .25rem;
                        transform: translateX(-50%) translateY(50%) rotate(45deg);
                    }
                }
            }

            &:hover {
                img {
                    filter: brightness(1);

                    & + p {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .checkboxWrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 27px;
        height: 27px;
        user-select: none;
        margin-right: .5rem !important;
        float: left;

        input[type="checkbox"],
        input[type="radio"] {
            position: absolute;
            display: block;
            height: 100%;
            z-index: 1;
            width: 100%;
            opacity: 0;
            cursor: pointer;

            & + .checkmark {
                position: relative;
                top: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 0;
                background-color: $white;
                height: 27px;
                width: 27px;
                border: 1px solid $primary;
                border-radius: 100%;
                transition: ease-in-out 0.1s;

                &:after {
                    opacity: 0;
                    content: '';
                    display: inline;
                    background: $primary;
                    border-radius: 100%;
                }
            }

            &:checked ~ .checkmark:after {
                opacity: 1;
                transition: all ease-in-out .6s;

            }
        }

        input[type="checkbox"] {
            &:checked ~ .checkmark {
                border: none;
                background: $primary;
            }

            & + .checkmark {
                border-radius: 7px;

                &:after {
                    background-image: url("../../../../assets/icons/check-white.svg");
                    background-size: 0.8em;
                    width: 100%;
                    height: 100%;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }
        }
    }

    input[type="radio"] {
        &:checked ~ .checkmark:after {
            width: calc(100% - .5rem);
            height: calc(100% - .5rem);
        }
    }
}
