@import "../../style/variables";
@import "../../style/mixins";

.contentContainer {
    --headerWidth: 6.5rem;

    h1:first-child {
        margin-top: 0;
        margin-bottom: 0.25rem;
    }

    &.bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0 2rem 0;
        min-width: auto;

        * {
            margin: 0;
        }
    }

    &.header {
        display: flex;
        align-items: center;
        margin: 0 0 2.5rem 0;
        padding: 0;
        min-width: auto;
        justify-content: space-between;

        >h1,
        >h2 {
            margin-bottom: 0;
        }

        .button {
            margin-top: 0 !important;
        }
    }

    &.center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-width: auto;
    }

    &.successPage {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        text-align: left;
        min-width: auto;
        padding: 2.5rem;
        grid-gap: 4rem;
    }

    &.small {
        margin: 0 auto;
        max-width: 600px;
        min-width: auto;
    }

    &.smallLeft {
        max-width: 600px;
        min-width: auto;
    }

    &.wide {
        width: 100%;
        max-width: 100%;
        min-width: auto;
    }

    &.headerGrid {
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-gap: 1.5rem;
        margin-bottom: 2rem;
        align-items: flex-end;

        h2 {
            font-weight: $regular;
            margin-bottom: 0;
        }
    }

    &.headerGrid.withFilter {
        grid-template-columns: 1fr 1fr;

        & > div:last-child {
            align-self: baseline;
            justify-self: flex-end;
        }
    }

    &.fullHeightContent {
        min-height: fit-content;
    }

    &.button {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 0;
        padding: 0;
        min-width: auto;
    }

    &.noPadding {
        padding: 0;
    }
}