@import "../../../../style/variables";

.colorpicker {
    position: relative;

    &_input_wrapper {
        position: relative;

        > div {
            position: relative;
        }
    }

    &.disabled {
        .colorpicker_input_wrapper {
            div:last-child {
                pointer-events: none;
            }
        }

    }

    .control {
        &-indicator {
            position: absolute;
            background-size: 1rem;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url("../../../../assets/icons/color-wheel.svg");
            cursor: pointer;
            height: 2.5rem;
            width: 2.5rem;
            right: 0;
            top: 0;
            border-left: 1px solid $primary;

            &.active {
                background-color: $primary !important;
            }
        }
    }
}
