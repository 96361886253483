@import '../../style/variables';

.button_wrapper {
    display: inline-flex;
    align-items: center;
    font-family: $roboto;
    font-size: .7rem;
    cursor: pointer !important;
    position: relative;
    z-index: 2;

    p {
        font-weight: $medium;
    }

    .button {
            display: inline-block;
            width: 2.5rem;
            height: 2.5rem;
            margin-right: .5rem;
            border-radius: $borderRadius;
            position: relative;

        &:global(.secondary) {
            background: $primary;

            & + p {
                color: $black;
            }
        }

        &:global(.primary) {
            background: $white;
            border: 1px solid $primary;

            & + p {
                color: $black;
            }
        }

        &:global(.plus),
        &:global(.minus) {
            &:before {
                content: '';
                position: absolute;
                display: inline-block;
                width: 1rem;
                height: 1rem;
                background-repeat: no-repeat;
                background-position: center center;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
            }
        }

        &:global(.plus) {
            &:before {
                background-image: url('../../assets/icons/plus.svg');
            }
        }

        &:global(.minus) {
            &:before {
                background-image: url('../../assets/icons/minus-primary.svg');
                background-size: .75rem;
            }

        }
    }

    .button {
        z-index: 1;
        transition: ease-in-out .6s;

        &:hover {
            position: relative;
            transition: ease-in-out .6s;
        }
    }

    & + .button_wrapper {
        margin-left: 2rem;
    }
}
