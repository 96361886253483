@import '../../style/variables';

.backButton {
    background: transparent;
    cursor: pointer !important;
    display: inline-flex;
    color: $primary;
    line-height: 0.6em;
    align-items: center;
    justify-content: center;
    font: $bold .8rem $roboto;
    white-space: nowrap;
    border: 0px;
    border-radius: .25rem;
    transition: .6s ease-in-out;
    vertical-align: top;
    text-decoration: none;

    &:before {
        content: '';
        background-image: url("../../assets/icons/chevron-left.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 1rem;
        height: .6rem;
        left: 0;
        top: -0.5px;
        display: inline-block;
        margin-right: 6px;
        position: relative;
    }
}


.chartWrapper {
    padding: 1.5rem;
    border-radius: .25rem;
    background: $gray-light;

    .chartHeadline {
        display: block;
        margin: 0 0 1.5rem 0 !important;
        color: $black;
        font-size: 22px !important;
        font-family: $roboto;
        font-weight: 900;
    }
}

.lineChartWrapper,
.barChartWrapper,
.geoChartWrapper,
.listChartWrapper {
    @extend .chartWrapper;
}

.lineChartWrapper,
.barChartWrapper {
    .chart {
        height: 35vh;
    }
}

.geoChartWrapper {
    .chart {
        height: 400px;
    }
}

.lineChartWrapper {
    .headlineWrapper {
        display: flex;
    }

    .totalClicks {
        text-align: right;
        flex: 1;
        font-size: 22px;
        font-weight: $bold;
        margin-left: 1rem;
        position: relative;
    }

    .totalClicks_label i {
        font-style: initial;
        font-size: 16px;
        color: $gray-dark;
        font-family: $robotoSlab;
        font-weight: $regular;
    }
}

.legend {
    list-style: none;

    &Item {
        font-size: 17px;
        color: $black;

        +.legendItem {
            margin-top: 1.25rem;
        }

        &Icon {
            display: inline-block;
            width: .5rem;
            height: .5rem;

            margin-right: .5rem;

            border-radius: 2px;
        }

        &Number {
            display: block;
            padding-left: 1rem;
            font-size: 28px;
            font-weight: bold;
        }
    }
}

.doughnutChartWrapper {
    @extend .chartWrapper;

    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: min-content 300px;
    grid-template-areas: "headline headline"
        "chart legend";

    padding: 1.5rem;

    .chartHeadline {
        grid-area: headline;
    }

    .chart {
        position: relative;
        grid-area: chart;
    }

    .legendWrapper {
        display: grid;
        grid-area: legend;
        align-items: center;
    }
}

.listChartWrapper {
    :global {
        .listChartEntry {
            p {
                font-size: .8rem !important;
            }
        }

        .listChartEntryBold {
            @extend .listChartEntry;
            font-weight: bold;
        }
    }
}

.chartGroup {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
}