:global {
    @mixin url {
        font-size: .7rem;
        text-decoration: underline;
        color: $primary;
    }

    @mixin align-center {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .Table__pagination {
        @include width-wrapper;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem !important;

        img {
            position: relative;
            top: 2px;
            padding: .75rem;
            cursor: pointer;

            &[disabled="true"] {
                opacity: 0;
            }
        }

        .Table__pageButton {
            font-family: $roboto;
            font-weight: 500;
            font-size: .75rem;
            border: none;
            background: none;
            position: relative;

            &--active {
                font-family: $roboto;
                width: 35px;
                height: 35px;
                border-radius: .25rem;
                justify-content: center;
                align-items: center;
                background: #318692;
                cursor: pointer;
                transition: background-color .2s ease-in-out;
                color: $white;

                .dots:before {
                    position: absolute;
                    opacity: 0;
                }
            }
        }

        .Table__nextPageWrapper,
        .Table__prevPageWrapper {
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            line-height: 1;
            color: $primary;

            &.disabled {
                color: $gray-medium;
                cursor: not-allowed;
            }

            span {
                line-height: 100%;
                margin-top: 3px;
            }
        }

        .dots:before {
            content: '...';
            left: 0;
            top: 0;
            position: relative;
            display: inline-block;
            text-align: left;
            margin-right: 26px;
            padding: 0 6px 0 0;
        }

        .Table__nextPageWrapper img {
            transform: rotate(180deg);
        }
    }

    body .DynamicTable {
        margin-top: 1rem;
        border: none;
        font-size: .8rem;
        color: $black;

        h1,
        h2,
        h3,
        h4,
        h5 {
            color: $black;
            display: inline-block;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        a {
            font-family: $roboto;
            text-align: left;
            font-weight: 700;
            text-decoration: none;
            margin: 0;
            line-height: 1.6em;
        }

        a {
            color: $primary;
            display: inline;
        }

        p {
            color: $black;
            line-height: 2rem;
            margin: 0;

            &.small {
                color: $black;
                font-size: .6rem;
                font-weight: $regular;
                line-height: 1.6em;
            }

            &.big {
                font-size: 24px;
                line-height: 1em;
                font-weight: $bold;
            }

            &.label {
                font-size: .5rem;
                padding: 2px .25rem;
                margin: 0 .5rem;
                border: 1px solid $primary;
                border-radius: $borderRadius;
                height: 1rem;
                color: $primary;
                font-weight: 600;
                display: inline-block;
                line-height: 1.6em;
            }
        }

        .center {
            display: flex;
            justify-content: center;
        }

        .between {
            display: flex;
            justify-content: space-between;
        }

        .left {
            justify-content: left;
            display: flex;
            align-items: center;
        }

        span.statusLabel {
            border-radius: .25em;
            color: #fff;
            display: inline;
            font-weight: 700;
            line-height: 1;
            padding: .2em .6em .3em;
            text-align: center;
            vertical-align: baseline;
            white-space: nowrap;
        }
    }

    body .ReactTable {
        margin-top: 1rem;
        border: none;
        font-size: .8rem;
        color: $black;

        h1,
        h2,
        h3,
        h4,
        h5,
        a {
            font-family: $roboto;
            color: $primary;
            text-align: left;
            font-weight: 700;
            text-decoration: none;
            margin: 0;
            line-height: 1.6em;
            display: inline-block;
        }

        p {
            color: $black;
            line-height: 2em;
            margin: 0;

            &.small {
                color: $black;
                font-size: .6rem;
                font-weight: $regular;
                line-height: 1.6em;
            }

            &.big {
                font-size: 24px;
                line-height: 1em;
                font-weight: $bold;
            }

            &.label {
                font-size: .5rem;
                padding: 2px .25rem;
                margin: 0 .5rem;
                border: 1px solid $primary;
                border-radius: $borderRadius;
                height: 1rem;
                color: $primary;
                font-weight: 600;
                display: inline-block;
                line-height: 1.6em;
            }
        }

        .center {
            display: flex;
            justify-content: center;
        }

        .left {
            justify-content: left;
        }

        .icons {
            display: flex;
            justify-content: center;
            width: 100%;
            padding-top: .5rem !important;

            img {
                cursor: pointer;
                width: .9rem;
            }
        }

        .margin_left {
            margin-left: 1rem;
        }

        .space_between {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .left {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        .click {
            width: 1rem;
            height: 1rem;
            margin-right: 2rem;
            margin-left: .5rem;
            border-radius: 100%;
            cursor: pointer;
            position: relative;
            display: inline-block;

            &.active {
                img {
                    transform: translateX(-50%) translateY(-50%) rotate(270deg);
                }
            }

            img {
                position: absolute;
                width: 35%;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%) rotate(90deg);
            }
        }

        .expand {
            margin-top: 2rem;
            display: none;

            &.active {
                display: block;
            }
        }

        .rt-noData {
            display: none;
        }

        .rt-thead {
            &.-header {
                padding-bottom: .25rem;
                box-shadow: none;
                border-bottom: 1px solid $gray-medium !important;
                font-weight: 700;
            }

            .rt-th,
            .rt-td {
                border-right: none;
            }

            .rt-th {
                font-family: $roboto;
                font-weight: $bold;
                color: $black;
                text-align: left;

                &.-sort-asc,
                &.-sort-desc {
                    box-shadow: none;
                }

                &.-sort-desc div:after {
                    background-image: url('/assets/icons/sortable-arrows-desc.png');
                }

                &.-sort-asc div:after {
                    background-image: url('/assets/icons/sortable-arrows-asc.png');
                }

                &.sort div {

                    &:after {
                        content: '';
                        position: relative;
                        left: .5rem;
                        display: inline-block;
                        width: 6px;
                        height: 8px;
                        background-image: url('/assets/icons/sortable-arrows.png');
                        background-repeat: no-repeat;
                    }
                }

                &:not(.sort) .rt-resizable-header-content {
                    &::after {
                        display: none !important;
                    }
                }
            }

            .rt-resizer {
                display: none !important;
            }
        }

        .rt-tbody {
            line-height: 160%;
            overflow: visible;

            .rt-tr {
                padding: .25rem 0;
            }

            :global(.long-value) {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-right: .5rem;
            }

            a:global {
                &.url {
                    display: block;
                    @include url;

                    &-inline {
                        @include url;
                        display: inline-block;
                    }
                }
            }

            .url {
                overflow: hidden;
            }

            .rt-tr-group {
                border-bottom: 1px solid $gray-light;

                .rt-td {
                    border-right: 0;
                    white-space: normal;
                    overflow: visible;
                }
            }
        }

        .pagination-bottom {
            position: relative;
            margin-top: 1rem;
            z-index: 1;
            display: flex;
            justify-content: center;
            width: 100%;
            height: 75px;
            bottom: 0;
            left: 0;
            background: linear-gradient(0, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5));

            &:before,
            &:after {
                content: '';
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                background: linear-gradient(0, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
                z-index: -1;
                left: 0;
                top: 0;
            }

            &:before,
            &:after {
                height: calc(100% + 2rem);
                top: -40px;
                pointer-events: none;
            }
        }

        +button {
            margin-top: 2.5rem;
        }
    }

    .animated {
        animation: breath;
        animation-duration: 800ms;
        animation-play-state: running;
        animation-timing-function: ease-out;
    }

    @keyframes breath {
        0% {
            background-color: transparent;
        }

        50% {
            background-color: rgba(44, 134, 147, .3);
        }

        100% {
            background-color: transparent;
        }
    }

    .linksCell {
        flex: 0 0 auto !important;
        min-width: 250px !important;
        width: auto !important;
    }

    .autoWidth {
        flex: 0 0 auto !important;
        width: auto !important;
    }

    .cellPulldown {
        margin-top: 1em;
    }

}
