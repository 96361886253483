//colors
$primary-light: #dfedef;
$primary: #318692;
$primary-hover: #1e656f;
$secondary:#9C9C9C;
$orange: #ff6e00;
$orange-light: #ffd6b6;

$lh-links: #318692;
$lh-links-light: transparentize($lh-links, 0.75);
$lh-links-hover: darken($lh-links, 0.75);
$lh-campaigns: #3bd5c3;

$lh-warning: #f40038;
$lh-success: #048fa1;

$gray-white: #FAFAFA;
$gray-light: #EEEEEE;
$gray-medium-light: #C0C0C0;
$gray-medium: #D2D2D2;
$gray-dark: $secondary;
$white: #FFFFFF;
$black: #292929;

//fonts
$roboto: 'Roboto',
sans-serif;
$robotoSlab: 'Roboto Slab',
serif;

$robotoMono: 'Roboto Mono',
mono;

//font-weights
$regular: 400;
$medium: 500;
$bold: 700;

//border-radius
$borderRadius: 0.5rem;

//branding
$playfairDisplay: 'Playfair Display',
serif;
$barlow: 'Barlow',
sans-serif;

//page wrapper
$maxWidth: 1200px;

$lh_colors: ("lh-links": $lh-links,
    "lh-campaigns":$lh-campaigns,
    "lh-default": $black,
);

@each $name,
$hex in $lh_colors {
    .underline__#{$name} {
        position: relative;
        padding-bottom: .5rem;

        &.centered {
            width: auto;
            justify-self: center;
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            bottom: -.25rem;
            left: 0;
            width: 1.5rem;
            height: 4px;
            background-color: #{$hex};
            border-radius: 1rem;
        }
    }
}

$reg_colors: ("orange": $orange,
    "primary": $primary,
    "muted" : $gray-dark,
);

@each $name,
$hex in $reg_colors {
    .#{$name}-t {
        color: #{$hex};
    }
}