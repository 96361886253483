@import "../../../style/variables";

.alignContent {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
    img {
        margin-right: 1rem;
    }
    
    p {
        font-weight:  $bold;
        white-space: nowrap;
    }
}
