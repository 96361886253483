@import '../../style/variables';

.required {
    font-size: 11px;
    align-items: center;
    white-space: nowrap;
    margin-right: 1rem !important;
    display: flex;
    height: 2rem;
}
