@import "../../style/variables";

.validator_box {
    background-color: $gray-white;
    position: absolute;
    right: -40px;
    padding: 1.5rem;
    border-radius: .5rem;
    transform: translateX(100%);
    
    &:before {
        content: '';
        position: absolute;
        width: .5rem;
        height: .5rem;
        display: inline-block;
        background-color: $gray-white;
        transform: rotate(45deg);
        left: -5px;
        top: 45px;
    }
    
    p {
        font-size: .6rem;
        line-height: 2em;
        margin: 0;
        color: $black !important;
        display: flex;
        align-items: center;
        
        &:before {
            content: '';
            position: relative;
            display: inline-block;
            width: .9em;
            height: .9em;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: 7px;
            transform: translateY(-1px);
        }
    
        &.valid {
            &:before {
                background-image: url("../../assets/icons/tick.svg");
            }
        }
    
        &.error {
            &:before {
                background-image: url("../../assets/icons/exclamation-mark.svg");
            }
        }
    
        &.default {
            &:before {
                background-image: url("../../assets/icons/circle.svg");
                background-size: .4em;
            }
        }
    }
}
