@import '../../../../style/variables';

.input_wrapper {

    label {
        display: block;
    }

    input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 100%;
        height: 2.5rem;
        padding: 0 1rem;
        border: 1px solid $primary;
        border-radius: $borderRadius;
        font-size: .7rem;
        background-image: url("../../../../../src/assets/icons/arrow-down.svg");
        background-position: calc(100% - 1rem) center;
        background-repeat: no-repeat;
        cursor: pointer;

        &::-webkit-calendar-picker-indicator {
            display: none;
        }

        &::placeholder {
            color: $gray-dark;
            font-size: .7rem;
            line-height: 2.5rem;
        }

        &:focus {
            outline: none;
        }
    }
}

.dropdown {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        pointer-events: none;
        width: 1px;
        height: 2.5rem;
        display: inline-block;
        background-color: $primary;
        right: 2.5rem;
        top: 0;
    }

    &.error {
        :global(.selectbox__control) {
            background-position: right 65px center;
            background-repeat: no-repeat;
            background-size: .75rem;
            transition: none;
        }
    }
}

datalist {

    width: 100% !important;
    min-width: 100% !important;
    background: red !important;

    option {
        color: red !important;
        line-height: 2rem !important;
        height: 2rem !important;
        width: 100% !important;
        min-width: 100% !important;
    }

}
