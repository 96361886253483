.tagCloudContainer {
    display: flex;
    flex-wrap: wrap;
}

.moreTagsButton {
    cursor: pointer;
    color: #2C8693;
    font-weight: bold;
    margin: 0;
    border: none;
    background-color: white;
    padding: 0;
}