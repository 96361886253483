@import "../../style/variables";

.overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh + 1rem);
}

.popup {
    position: relative;
    background: $white;
    width: 100%;
    max-width: 750px;
    padding: 2.5rem;
    border-radius: $borderRadius;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    
    
    .content_wrapper {
        max-width: 620px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        h2 {
            margin-top: 0;
            font-size: 36px;
        }
    }
    
    .warning {
        background: #ffe4d3;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: $borderRadius;
        text-align: left;
        min-width: 360px;
        padding: .5rem 1rem .5rem 0;
        
        img {
            width: 1rem;
            margin: 0 1rem;
        }
        
        p {
            font-size: .7rem;
        }
    }
    
    label {
        font-size: .8rem;
        margin: 2rem 0 1rem 0;
        font-weight: $regular;
    }
    
    .button_wrapper {
        display: flex;
        max-width: 450px;
        justify-content: center;
        margin: auto;
        width: 100%;
    }
    
    .cancel {
        position: absolute;
        width: 1.25rem;
        right: 1rem;
        top: 1rem;
        cursor: pointer;
    }
    
    p {
        margin: 0;
        
        + .button_wrapper {
            margin-top: 1.5rem;
        }
    }
    
    form {
        max-width: 510px;
    }
    
    .paypal_button_height {
        height: 55px;
    }
    
    button {
        margin: auto;
        align-self: center;

        &:first-child {
            width: 40%;
        }
        
        &:last-child {
            width: 60%;
        }
    }
}
