@import "variables";

:global {
    .input_wrapper {
        margin-bottom: 1rem;

        label {
            display: block;
        }

        &.file_wrapper {
            position: relative;
            overflow: hidden;
            display: inline-block;

            div {
                border: 1px solid $primary;
                border-radius: $borderRadius;
                background-color: $white;
                // TODO: Adjust this
                background-image: url('/assets/icons/plus-black.svg');
                background-position: right .75rem center;
                background-repeat: no-repeat;
                padding: .75rem 1rem;
                width: 100%;
                height: 2.5rem;
                font-size: .7rem;
                color: rgba(0, 0, 0, .3);
                position: relative;

                &:before {
                    content: attr(data-text);
                }

                &:after {
                    content: '';
                    position: absolute;
                    right: 45px;
                    height: 100%;
                    top: 0;
                    border-right: 1px solid $primary;
                }
            }

            input[type=file] {
                font-size: 100px;
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
            }
        }
    }

    input {
        width: 100%;
        height: 2.5rem;
        padding: .75rem 1rem;
        border: 1px solid $primary;
        border-radius: $borderRadius;
        font-size: .7rem;

        &::placeholder {
            color: $gray-dark;
        }

        &[type="file"] {
            font-size: .7rem;
            padding-left: 0;
            // TODO: REMOVE this
            border: 1px solid #ff00ff;
        }

        &:focus,
        &:active {
            outline: none;
        }

        &:disabled {
            background-color: $gray-light;
            color: $gray-dark;

            &::placeholder {
                color: $gray-dark;
            }
        }
    }

    textarea {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        min-height: 2.5rem;
        height: 5rem;
        max-height: 5rem;
        padding: .75rem 1rem;
        border: 1px solid $primary;
        border-radius: $borderRadius;
        font-size: .7rem;
        font-family: "Roboto", sans-serif;

        &::placeholder {
            color: $gray-dark;
        }

        &:focus,
        &:active {
            outline: none;
        }

        &:disabled {
            background-color: $gray-light;
            color: $gray-dark;

            &::placeholder {
                color: $gray-dark;
            }
        }
    }

    .error_message {
        margin-top: .5rem;
        text-align: left;
        font-size: .5rem;
        font-family: $roboto;
        font-weight: $bold;
        color: $black;
    }

    .error_message.orange-t {
        color: $lh-warning;
    }

    .errorMessage{
        font-family: $roboto;
        font-weight: $bold;
    }

    .validation_message.orange-t {
        font-family: $roboto;
        font-size: .5rem;
        margin-top: .5rem;
    }

    .error {
        // TODO: Adjust this
        background-image: url('/assets/icons/warning.svg') !important;
        background-position: right .75rem center;
        background-repeat: no-repeat;
        background-size: .75rem;
        position: relative;
    }
}