@import '../../../../style/variables';

.content-container {
    >* {
        margin-top: 0;
    }

    h1 {
        margin-bottom: 0.5rem;
    }

    table {
        margin: 1.5rem 0 1rem 0;
        display: table;
        flex-direction: none;

        * {
            vertical-align: top;
            line-height: 1.4em;
        }

        .shorten {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 400px;
        }
    }
}

.shorten {
    font-family: $robotoSlab !important;
    max-width: 430px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.tableGeneral {
    background: $gray-light;
    padding: 1rem;
    border-radius: 5px;
    font-size: 0.825rem;

    * {
        text-align: left;
    }

    td {
        min-width: 120px;
        font-weight: $regular;
    }

    tr td:first-child {
        font-weight: 700;
    }

    tr {
        padding-bottom: 1rem;
        display: block;
    }
}