#accountInactive {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    
    img {
        margin-bottom: .5rem;
    }
    
    p {
        margin-bottom: 0;
        + p {
            margin-top: 1rem;
        }
    }
}
