@import '../../../../style/variables';


.input_wrapper {
    margin-bottom: 1rem;

    label {
        display: block;
    }

    &.error {
        :global(.selectbox__control) {
            background-image: url('/assets/icons/warning.svg');
            background-position: right 65px center;
            background-repeat: no-repeat;
            background-size: .75rem;
            transition: none;
        }

        :after {
            bottom: .6rem;
        }
    }

    :global {
        .selectbox {
            font-size: .7rem;

            &__value-container {
                display: flex;
                padding: 0 1rem;
                flex-wrap: nowrap;
                height: 2rem;

                &::-webkit-scrollbar {
                    display: none;
                }

                :global {
                    .selectbox__multi-value {
                        background: $primary;
                        color: $white;

                        &__label {
                            color: $white;
                            margin-right: .25rem;
                        }

                        &__remove:hover {
                            background-color: $primary-hover;
                            color: $white;
                            cursor: pointer;
                        }
                    }
                }
            }

            &__placeholder {
                font-size: .7rem;
                color: $gray-dark;
            }

            &__control {
                border-color: $primary !important;
                min-height: 2.5rem;
                border-radius: $borderRadius !important;

                &--menu-is-open {
                    :global(.selectbox__dropdown-indicator) {
                        background-image: url('../../../../assets/icons/arrow-up.svg');
                        background-color: $primary;
                        border-radius: 0 $borderRadius $borderRadius 0;
                    }
                }

                &--is-focused {
                    box-shadow: none;
                }
            }

            &__option {
                cursor: pointer;
                padding: .5rem 1rem;
            }

            &__option--is-focused,
            &__option--is-selected {
                background: $white;
                color: $primary;
            }

            &__dropdown-indicator {
                background: url('../../../../assets/icons/arrow-down.svg');
                background-repeat: no-repeat;
                background-position: center center;
                height: 100%;
                width: 2.5rem;
                cursor: pointer;

                & svg {
                    display: none;
                }
            }

            &__indicator-separator {
                background: $primary;
                margin: 0;
                height: 100%;
            }

            &__menu {
                margin-top: 0;
                background: $white !important;
                color: $primary;
                font-family: $roboto;
                font-size: .7rem;
                z-index: 10;
            }

            &__menu-list {
                padding: .5rem 0;
            }

            &__option {
                &:hover {
                    background-color: $primary !important;
                    color: $white !important;
                }
            }
        }
    }

    .error_message {
        margin-top: .5rem;
        text-align: right;
        font-size: .5rem;
        font-family: $roboto;
        font-weight: $bold;
        color: $black;
    }
}