@import '../../../../style/variables';
@import url('https://fonts.googleapis.com/css2?family=Acme&family=Itim&family=Handlee&family=Neucha&family=Mali&family=Leckerli+One&family=Fondamento&family=Dancing+Script&family=Do+Hyeon&family=Fondamento&family=Indie+Flower&family=Lora&family=Merriweather&family=Noto+Serif&family=Nunito&family=Open+Sans&family=Oswald&family=PT+Serif&family=Pacifico&family=Playfair+Display&family=Roboto+Mono&family=Rowdies&family=Barlow&family=Open+Sans+Condensed:wght@300&family=Sora&family=Ubuntu&family=Zilla+Slab&display=swap&family=Inter&family=Alice');


.input_wrapper {
    label {
        display: block;
    }

    :global(.selectbox__control--is-disabled) {
        background: #F2F2F2;
        color: $gray-dark;
    }

    :global(.selectbox__placeholder) {
        color: $gray-dark;
    }

    &.error {
        :global(.selectbox__control) {
            background-image: url('/assets/icons/warning.svg');
            background-position: right 65px center;
            background-repeat: no-repeat;
            background-size: .75rem;
            transition: none;
        }

        :after {
            bottom: .6rem;
        }
    }

    :global {
        .selectbox {
            font-size: .7rem;
            font-weight: $regular;

            &__value-container {
                display: flex;
                padding: 0 1rem;
                flex-wrap: nowrap;
                height: 2rem;
            }

            &__placeholder {
                font-size: .7rem;
                font-weight: $regular;
                color: $gray-dark;
            }

            &__control {
                border-color: $primary !important;
                height: 2.5rem;
                border-radius: $borderRadius;

                &--menu-is-open {
                    :global(.selectbox__indicator) {
                        background-image: url('../../../../assets/icons/arrow-up.svg');
                        background-color: $primary;
                        border-radius: 0 2rem 2rem 0;
                        top: -1px;
                        right: -1px;
                        left: 0;
                        position: relative;
                    }
                }

                &--is-focused {
                    box-shadow: none;
                }
            }

            &__option {
                cursor: pointer;
                padding: .5rem 1rem;
            }

            &__option--is-focused,
            &__option--is-selected {
                background: $white;
                color: $primary;
            }

            &__indicator {
                background: url('../../../../assets/icons/arrow-down.svg');
                background-repeat: no-repeat;
                background-position: center center;
                height: 2.5rem;
                width: 2.5rem;
                cursor: pointer;

                & svg {
                    display: none;
                }
            }

            &__indicator-separator {
                background: $primary;
                margin: 0;
                height: calc(2.5rem - 2px);
            }

            &__menu {
                margin-top: 0;
                background: $primary;
                color: $white;
                font-family: $roboto;
                font-size: .7rem;
            }

            &__menu-list {
                padding: .5rem 0;
            }

            &__option {
                &:hover {
                    background-color: $primary !important;
                    color: $white !important;
                }
            }
        }
    }
}
