@import '../../style/variables';

.wrapper {
    position: fixed;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: none;
    background: rgba(255, 255, 255, 0.3);
    z-index: 10000;

    &.show {
        display: flex;
    }

    &.inline{
        position: relative;
        flex: 1;
    }

    .wrapper_circle {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70px;
        height: 70px;

        .circle_dark {
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 200px;
            background: $primary-hover;
            animation-name: zoomIn;
            animation-duration: 1s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
            animation-direction: alternate;
        }

        .circle_light {
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 200px;
            background: $primary;
            opacity: .7;
            animation-name: zoomIn;
            animation-duration: 1s;
            animation-timing-function: ease-in-out;
            animation-delay: 500ms;
            animation-iteration-count: infinite;
            animation-direction: alternate;
        }
    }

    p {
        font-family: $robotoSlab;
        font-size: .8rem;
    }
}

@keyframes zoomIn {
    from {
        transform: scale3d(1, 1, 1);
    }

    to {
        transform: scale3d(.3, .3, 1);
    }
}
