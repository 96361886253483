.password {
    &_container {
        
        img {
            width: 350px;
            margin: auto;
        }
    
        h1 + p {
            margin: -10px auto 1.5rem auto;
        }
        
        h1, p {
            text-align: center;
        }
        
        h1 {
            white-space: nowrap;
        }
        
        label {
            text-align: left;
        }
    
        form {
            position: relative;
        
            button {
                width: 100%;
            }
        }
    }
    
    &_wrapper {
        max-width: 420px;
        margin: auto;
        
       
    }
}


