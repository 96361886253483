@import '../../style/variables';


.fullWidth {
    width: 100%;
    margin-bottom: 1rem;

    table {
        border-spacing: 0;
        width: 100%;
        border: none;
        font-size: 14px;

        tr {
            padding: 1rem .5rem;

            &:last-child {
                td {
                    border-bottom: 0;
                }
            }

            td {
                height: 2.5rem;
            }
        }

        th,
        td {
            margin: 0;
            padding: 0.5rem;
            font-size: 14px;

            :last-child {
                border-right: 0;
            }
        }

        thead th {
            border-bottom: 1px solid $gray-medium;
            padding: 1rem .5rem;
        }
    }
}

table {
    width: 100%;
}

.nothingToShow {
    display: flex;
    width: 100%;
    height: 300px;
    justify-content: center;
    align-items: center;
}

:global {
    .columnHead {
        &.end {
            &>span {
                justify-content: flex-end;
            }
        }

        &.center {
            &>span {
                justify-content: center;
            }
        }

        &>span {
            white-space: nowrap;
            display: flex;
            flex-direction: row;
            align-items: center;

            &>span {
                height: 1rem;
                width: 1rem;
                color: $primary;
                align-items: center;
                display: inline-flex;

                svg {
                    height: 0.75rem;
                    width: 0.75rem;
                }
            }
        }

        &.active {
            color: $primary;
        }
    }
}

.dimmed td {
    color: $gray-dark;
}

table tr {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 5px;
        z-index: -1;
        background-color: transparent;
        transition: background-color 500ms ease-in-out;
    }

    &:hover {
        &::before {
            background-color: transparentize($gray-dark, .9);
        }

        :global {
            .resetHoverOnCopy {
                background-color: $white !important;
            }
        }
    }
}

.danger {
    position: relative;

    &::before {
        background-color: transparentize($lh-warning, .9);
    }
}

.highlight{
    position: relative;

    &::before {
        background-color: transparentize($primary, .9);
    }
}

.tableCell {
    white-space: nowrap;
}

.checkboxWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 1rem;
    height: 1rem;
    user-select: none;
    margin-right: .5rem !important;
    float: left;

    input[type="checkbox"],
    input[type="radio"] {
        position: absolute;
        display: block;
        height: 100%;
        z-index: 1;
        width: 100%;
        opacity: 0;
        cursor: pointer;

        &+.checkmark {
            position: relative;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 0;
            background-color: $white;
            height: 1rem;
            width: 1rem;
            border: 1px solid $primary;
            border-radius: 100%;
            transition: ease-in-out 0.1s;

            &:after {
                opacity: 0;
                content: '';
                display: inline;
                background: $primary;
                border-radius: 100%;
            }
        }

        &:checked~.checkmark:after {
            opacity: 1;
            transition: all ease-in-out .6s;

        }
    }

    input[type="checkbox"] {
        &:checked~.checkmark {
            border: none;
            background: $primary;
        }

        &+.checkmark {
            border-radius: 7px;

            &:after {
                background-image: url("../../assets/icons/check-white.svg");
                background-size: 0.8em;
                width: 100%;
                height: 100%;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
    }
}

input[type="radio"] {
    &:checked~.checkmark:after {
        width: calc(100% - .5rem);
        height: calc(100% - .5rem);
    }
}