@import "../../style/variables";

button.csv {
    border: none;
    background: none;
    display: flex;
    max-width: 175px;
    align-items: center;
    justify-content: center;
    grid-gap: .5rem;

    span {
        color: $primary;
        font: $medium .8rem $roboto;
        line-height: 22px;
    }

    img {
        margin-left: 8px;
        height: 22px;
        width: 22px;
    }
}

button:disabled.csv {
    span {
        color: gray;
    }
}