@import '../../style/variables';
@import '../../style/mixins';

@mixin buttonStyle {
    cursor: pointer !important;
    display: inline-flex;
    height: 2.5rem;
    line-height: 1.5;
    align-items: center;
    justify-content: center;
    padding: 0 1.5rem;
    font: $medium .7rem $robotoSlab;
    white-space: nowrap;
    border: 1px solid $primary;
    border-radius: $borderRadius;
    margin: .5rem 0;
    transition: 0.6s ease-in-out;
    vertical-align: top;
    text-decoration: none;

    &:hover {
        transition: 0.6s ease-in-out;
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 .75rem 0 transparentize($black, .5);
    }

    + .button_primary,
    + .button_secondary {
        margin-right: 0;
    }

    @media (max-width: 500px) {
        margin: .5rem auto;
    }

    &:disabled{
        &:hover{
            color: gray;
            background-color: $white;
        }
        color: gray;
            background-color: $white;
    }

}

.button {
    &_primary {
        @include buttonStyle;
        background: $primary;
        color: $white;
        min-width: 10rem;

        &:hover {
            background: $primary-hover;
        }
    }

    &_secondary {
        @include buttonStyle;
        background: $white;
        color: $black;
        min-width: 10rem;

        &:hover {
            background: $primary-hover;
            border: 1px solid $primary-hover;
            color: $white;
        }
    }
}


button + * {
    margin-left: 1rem !important;
}
