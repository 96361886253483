$tagBackground: #2C8693;

:global {
    .tagContainer {
        display: flex;
        flex-wrap: wrap;

        .tagsCell & {
            flex-wrap: nowrap;
            align-items: center;
            justify-content: flex-start;
        }

        .tag {
            margin-right: .5rem;
            margin-bottom: .5rem;
            background-color: $tagBackground;
            height: 1.5rem;
            line-height: 1.5rem;
            padding: 0px 8px 0px 8px;
            color: white;
            border-radius: 2px;

            +.hidden {
                display: none;
            }
        }
    }
}