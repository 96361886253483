@import '../../style/variables';

.steps_container {
    display: flex;
    justify-content: space-between;
    position: absolute;
    right: 0;
    margin: 1rem 0;

    .active-bar {
        content: '';
        height: 2px;
        background: $primary-hover;
        position: absolute;
        top: calc(50% - 2px);
        left: 35px;
        transform: translateY(50%);
        z-index: 0;
        transition: width ease-in-out 0.3s;
    }

    >span {
        width: 35px;
        height: 35px;
        background: $white;
        border-radius: 7px;
        display: inline-block;
        line-height: 35px;
        font-weight: 600;
        z-index: 1;
        position: relative;
        font-size: .7rem;
        cursor: pointer;
        text-align: center;
        border: 1px solid $gray-light;

        &[data-active="true"] {
            background: $primary;
            color: $white !important;
            border: 1px solid $primary;

            .description {
                font-weight: 600 !important;
            }
        }

        &[data-disabled="true"] {
            color: $gray-medium !important;
            cursor: default !important;
            pointer-events: none;

            .description {
                color: $gray-medium !important;
            }
        }

        .description {
            position: absolute;
            top: -100%;
            left: 50%;
            transform: translateX(-50%);
            font-weight: 400;
            line-height: 1em;
            color: $black;
            white-space: nowrap;
        }
    }

    &:before {
        content: '';
        width: 100%;
        height: 2px;
        background: rgb(200, 218, 222);
        position: absolute;
        top: calc(50% - 2px);
        transform: translateY(50%);
        z-index: 0;
        transition: width ease-in-out 0.3s;
    }

    +h1 {
        margin: auto;
        text-align: center;
    }

}