@import './_variables';
@import './_fonts';
@import './_mixins';
@import './_normalize';
@import './_keyframes';
@import './_tooltips';
@import './react-table-overwrite';
@import './forms';
@import './inputs';
@import './calendar';
@import './tags';
@import './uppy';
@import './socion';

img {
    display: block;
    width: 100%;
    height: auto;
}

html,
body {
    margin: 0;
    font-family: $roboto;
    font-weight: normal;
    color: $black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*,
*:after,
*:before {
    box-sizing: border-box;
}

:global {
    #app {
        position: relative;
        min-height: 100vh;
        margin: auto;
        width: 100%;
        min-width: 1300px;
    }

    .lh__layout {
        &-inner {
            &-grid {
                display: grid;
                grid-template-rows: minmax(calc(100vh - 10rem), auto) 10rem;
                grid-template-columns: 6.5rem 1fr;

                &-content {
                    grid-column: 2;
                    grid-row: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    padding: 2rem 0 0;

                    &>div,
                    &>section {
                        @include width-wrapper;
                        position: relative;
                    }
                }
            }

            .btn-rounded-icon {
                @include flex-center;
                border-radius: 2.5rem;
                background-color: transparent;
                color: currentColor;
                width: 2.5rem;
                height: 2.5rem;
                margin: 0 auto;

                span {
                    line-height: 1;
                }

                svg {
                    width: 1.7rem;
                    height: 1.7rem;
                }

                &.helpdesk {
                    background-color: $primary;
                    color: $white;
                    position: fixed;
                    bottom: 2.5rem;
                    z-index: 10;
                    right: 2.5rem;

                    &:hover {
                        background-color: $primary-hover;
                    }
                }
            }
        }
    }

    .content {
        min-height: 100vh;
    }

    .width-wrapper {
        @include width-wrapper;
    }

    .overlay {
        position: absolute;
        z-index: 1;
        background: rgba(0, 0, 0, 0.6);
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
    }

    .rotate {
        &_90 {
            transform: rotate(90deg);
            transform-origin: center center;
        }
    }

    //helpscout button
    .BeaconFabButtonFrame {
        opacity: 0 !important;
        pointer-events: none !important;
    }

    // HEADER
    .headerRow {
        display: grid;
        grid-template-rows: minmax(2rem, 1fr) auto;
        grid-gap: 1.5rem;

        p {
            margin-bottom: 0;
        }

        h1 {
            span.icon {
                position: absolute;
                transform: translateX(-150%);
                margin-top: 0.25rem;
                opacity: .5;
            }
        }

        h2 {
            margin-top: 0;
        }
    }

    // PULSE BUTTON

    .addButton {
        display: flex;
        align-self: flex-end;
        justify-content: flex-end;
        margin-bottom: 1rem;
        position: relative;
        cursor: pointer;

        &:before {
            content: "";
            font: 500 0.7rem "Roboto Slab", serif;
            position: absolute;
            background: $primary;
            height: 100%;
            display: flex;
            align-items: center;
            padding: 0 1.25rem 0 1.25rem;
            overflow: hidden;
            text-overflow: ellipsis;
            border-radius: 2.5rem;
            color: $white;
            width: 0;
            white-space: nowrap;
            transition: .6s width ease-in-out 100ms;
        }

        &.sidebar {
            &:before {
                content: "Create something new";
            }
        }

        &.links {
            align-self: flex-start;

            &:before {
                content: "Create new link";
            }
        }

        &.campaigns {
            align-self: flex-start;

            &:before {
                content: "Create new campaign";
            }
        }

        &.groups {
            align-self: flex-start;

            &:before {
                content: "Create new group";
            }
        }

        &:hover {
            &:before {
                width: 11.5rem;
            }
        }
    }

    .btn_icon_rounded_pulse {
        background: $primary;
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5rem;
        color: $white;
        position: relative;
        cursor: pointer;

        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 5rem;
            background: $primary;
            opacity: 0.25;
            z-index: 0;
            animation-name: zoomInUp;
            animation-duration: 2s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
            animation-direction: alternate;
        }

        svg {
            width: 1.5rem !important;
            height: 1.5rem !important;
            z-index: 2 !important;
            position: relative !important;
        }
    }
}
